import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CloneObject } from 'src/app/helper/CloneObject';

@Component({
  selector: 'app-dialog-filter-classification',
  templateUrl: './dialog-filter-classification.component.html',
  styleUrls: ['./dialog-filter-classification.component.scss']
})
export class DialogFilterClassificationComponent implements OnInit {
  @Input() filter: any;
  @Output() closeEmitter = new EventEmitter<undefined>();
  @Output() applyFilterEmitter = new EventEmitter<undefined>();
  public selectedClassification: number[] = [];

  constructor() { }

  ngOnInit(): void {
    for(let u of this.filter.classification){
      this.selectedClassification.push(CloneObject.deepCopy(u));
    }
  }

  selectionClassification(classification: number) {
    let index = this.selectedClassification.findIndex(r => r == classification);
    if(index > -1) this.selectedClassification.splice(index, 1);
    else this.selectedClassification.push(CloneObject.deepCopy(classification));
  }

  close() {
    this.closeEmitter.emit();
  }

  applyFilter() {
    this.filter.classification = this.selectedClassification;
    this.applyFilterEmitter.emit(this.filter);
  }

}

<div class="dialog_view">
    <div class="dialog_window">
        <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
        <div>
            <div class="dialog_window_header">
                <h2>Label bearbeiten</h2>
            </div>
            <div class="dialog_window_body">
                <form id="ticket-update-container" [formGroup]="ticketForm" *ngIf="ticketForm">
                    <ul class="ticket-types-list">
                        <li *ngFor="let type of selectTypes">
                          <button
                            type="button"
                            [ngClass]="{
                              'todo': selectedType === 1 && type.value == selectedType,
                              'feature': selectedType === 2 && type.value == selectedType,
                              'bug': selectedType === 3 && type.value == selectedType,
                              'doku-frage': selectedType === 4 && type.value == selectedType || selectedType === 5 && type.value == selectedType,
                              'feature-intern': selectedType === 6 && type.value == selectedType
                            }"
                            (click)="onSelectType(type)">
                            <span class="name">{{ type.label }}</span>
                          </button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
        <button class="dialog_window_btn_submit" (click)="updateTicket()">Speichern</button>
    </div>
</div>
  
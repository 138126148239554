
<div class="view_body_planning">
  <div id="phonelist_header">
    <h1>Telefonliste</h1>
  </div>
  <div class="buttons-header">
    <div id="phone_create_button_wrapper">
      <button class="btn_tab btn_tab_blue">Planung</button>
      <button class="btn_tab btn_tab_blue_light" (click)="switchViewListEmitter.emit()">Bearbeitung</button>
    </div>
    <div id="phone_create_button_wrapper">
      <button class="btn_tab btn_tab_blue_light" (click)="switchViewCreateEmitter.emit()">Anruf erstellen</button>
    </div>
  </div>
  <div class="planning_container">
    <div class="container_header">
      <label class="lbl_date">{{ displayDate }}</label>
      <div id="action_menu">
        <img *ngIf="displayDate != 'Heute'" class="left_next_arrow" src="./assets/arrowmenucolor.svg" (click)="changeDate(-1)">
        <img *ngIf="displayDate === 'Heute'" class="left_next_arrow_invalid" src="./assets/arrowmenuinvalid.svg" (click)="changeDate(-1)">
        <img class="right_next_arrow" src="./assets/arrowmenucolor.svg" (click)="changeDate(1)">
        <input id="datepicker" #inputDate1 type="date" [min]="minDate" [value]="currentDate" (change)="onDateInputChange($event)">
      </div>
    </div>
    <div class="details_body">
      <div class="column_one" *ngFor="let callBlock of orderedCalls, let iBlock = index">
        <div class="list_title">{{iBlock == 0 ? 'Vormittag' : 'Nachmittag'}}</div>
        <div id="container-v-h" *ngFor="let callPriorities of callBlock, let iPriority = index">
          <div class="list_title_inner" (click)="callPriorities.show = !callPriorities.show">{{iPriority == 0 ? 'Hohe Priorität' : ((iPriority == 1) ? 'Mittlere Priorität' : 'Niedrige Priorität')}}
            <img class="list_title_inner_icon" [ngStyle]="{'transform': callPriorities.show ? 'rotate(180deg)' : ''}" src="./assets/arrowdropdown.svg">
          </div>
          <div
            (drop)="onDrop($event, (iBlock == 0 ? 'morning' : 'afternoon'), (iPriority == 0 ? 'high' : (iPriority == 1 ? 'middle' : 'low')))">
            <div *ngIf="callPriorities.calls.length > 0 && callPriorities.show">
              <div class="list_element" *ngFor="let call of callPriorities.calls; let i = index"
                [draggable]="true"
                (dragstart)="onDragStart($event, call, (iBlock == 0 ? 'morning' : 'afternoon'), (iPriority == 0 ? 'high' : (iPriority == 1 ? 'middle' : 'low')))"
                (dragover)="onDragOver($event, call, (iBlock == 0 ? 'morning' : 'afternoon'), (iPriority == 0 ? 'high' : (iPriority == 1 ? 'middle' : 'low')))">
                <div class="list-icon-drag">
                  <div class="list-icon-drag-icon"></div>
                  <div class="list-icon-drag-icon"></div>
                </div>
                <div class="list_element_inner">
                  <label class="lbl-bold">
                    {{call.getName()}}
                  </label>
                  <label>
                    &nbsp;{{call.getCompany()}}
                  </label>
                  <a [href]='call.customer_link' target="_blank" *ngIf="call.customer_link">
                    <img src="./assets/link.svg"/>
                  </a>
                </div>
                <label class="list_element_inner">{{call.phone}}</label>
                <label class="list_element_inner">{{call.time_type == 0 ? (call.due_date | date: 'HH:mm') : '---'}}</label>
                <!-- <label class="list_element_inner">{{call.due_date | date: 'HH:mm'}}</label> -->
                <label class="list_element_inner">{{call.note ? call.note: ' ---'}}</label>
                <div class="list_element_inner">
                  <label class="lbl-tag"
                  [ngClass]="{
                    'kunde': call.category == 'Kunde',
                    'interessent': call.category == 'Interessent',
                    'partner': call.category == 'Partner',
                    'lieferant': call.category == 'Lieferant',
                    'privat': call.category == 'Privat',
                    'sonstige': call.category == 'Sonstige'
                  }">{{call.category}}</label>
                </div>
                <app-phonelist-action-menu *ngIf="!showPopup"
                  (editEmitter)="openEditView(call)"
                  (deleteEmitter)="deleteCall(call)"
                  (openPopupEmitter)="showPopupWindow($event, call)"
                  (moveEmitter)="selectionMove($event)"
                ></app-phonelist-action-menu>
              </div>
            </div>
            <div (dragover)="onDragOver($event, null, (iBlock == 0 ? 'morning' : 'afternoon'), (iPriority == 0 ? 'high' : (iPriority == 1 ? 'middle' : 'low')))" class="empty-list-box" *ngIf="callPriorities.calls.length == 0">
              <label>Derzeit sind keine Anrufe mit {{(iPriority == 0 ? 'hoher' : (iPriority == 1 ? 'mittlere' : 'niedriger'))}} Priorität geplant.<br>Anruf hierher ziehen um ihm diese Priorität zuzuweisen.</label>
            </div>
          </div>
        </div>
      </div>

      <app-phonelist-popup *ngIf="showPopup"
        [selectedCall]="popupCallSelected"
        [showWindow]="popupWindow"
        [timeStampSets]="timeStampSets"
        (selectionSaveEmitter)="closeAndUpdatePopup($event)"
        (selectionCloseEMitter)="closePopup()"
      ></app-phonelist-popup>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog-information',
  templateUrl: './dialog-information.component.html',
  styleUrls: ['./dialog-information.component.scss']
})
export class DialogInformationComponent implements OnInit {
  public show = false;
  public title!: string;
  public message!: string;
  public btnText!: string;
  public submitValue: any;
  public typ: any;
  public subscription: Subscription;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openInformationDialog$.subscribe((data: any) => {
      this.show = true;
      this.title = data.title;
      this.message = data.message;
      this.btnText = data.btn_submit_txt;
      this.submitValue = data.submit_value;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.subscription) this.subscription.unsubscribe();
  }

  selectionSubmit() {
    if(this.submitValue){
      this.dialogService.closeInformation(
        {
          submit_value: this.submitValue,
        }
      );
    }
    this.show = false;
  }
}

<div id="ticket-container" *ngIf="!isCreate">
  <div id="ticket-header">
    <!-- <div id="ticket-header-btn-wrapper">
      <button [disabled]="true">Meine Tickets</button>
      <button>Alle Tickets</button>
    </div> -->
    <div class="search-input-wrapper">
      <input #search id="input-search-tickets" type="text" placeholder="Tickets durchsuchen" (input)="applyFilter(search.value, 400)">
      <img src="./assets/delete-gray.svg" (click)="resetFilter()" *ngIf="!isPhone">
    </div>
    <div class="filter-wrapper" (click)="showFilterTickets = true"><label *ngIf="activeFilterCount > 0" class="lbl_counter">{{ activeFilterCount }}</label>
      <svg xmlns="http://www.w3.org/2000/svg" width="11.249" height="12.275" viewBox="0 0 11.249 12.275">
        <path id="filter_1_" data-name="filter (1)" d="M7.65,12.275a.511.511,0,0,1-.307-.1L5.3,10.639a.511.511,0,0,1-.2-.409V7.355L1.5,3.318A1.995,1.995,0,0,1,3,0h7.263a1.995,1.995,0,0,1,1.49,3.318L8.162,7.355v4.409A.511.511,0,0,1,7.65,12.275Z" transform="translate(-1.002)" fill="#3b88cc"/>
      </svg>
    </div>
    <label class="lbl_ticketcount" *ngIf="!isPhone">{{openTicketsCount}} offen, {{tickets.length - openTicketsCount}} gelöst</label>
    <div id="ticket-header-btn-wrapper">
      <!-- <button [class.button-inactive]="filter.open == false" (click)="filter.open = true; search.value = ''; applyFilter()">{{openTicketsCount}} offen</button>
      <button [class.button-inactive]="filter.open == true" (click)="filter.open = false; search.value = ''; applyFilter()">{{tickets.length - openTicketsCount}} gelöst</button> -->
    </div>
  </div>
  <div id="ticket-body">
    <div id="ticket-list">
      <app-tickets-item *ngFor="let ticket of ticketsFiltered"
        (click)="toggleTicketlist()"
        [ticket]="ticket"
        [active]="ticket.threadId == selectedTicket$.getValue()?.threadId"
        (selectionItemEmitter)="selectionTicket($event)"
        (selectionResolveEmitter)="resolve($event)"
        (selectionRestoreEmitter)="selectionRestore($event)"
      ></app-tickets-item>
      <label class="ticket-label-info lbl-empty" *ngIf="ticketsFiltered.length == 0">Es wurden keine Element gefunden.</label>
    </div>
    <div id="ticket-details" *ngIf="selectedTicket$.getValue() != null">
      <div id="ticket-details-body">
        <div id="ticket-details-header">
          <div id="ticket-details-header-images">
            <img class="ticket-details-back-btn" src="./assets/arrowdropdown.svg" (click)="toggleTicketlist()" *ngIf="isPhone">
            <img id="ticket-details-body-user-img" [src]="selectedTicket$.getValue().user.getAvatar()"/>
          </div>
          <div class="ticket-details-header-txt-wrapper">
            <div>
              <label class="ticket-title-bold">#{{selectedTicket$.getValue().threadId}}</label>
              <label class="ticket-title" [innerHTML]="selectedTicket$.getValue().subject != null &&  selectedTicket$.getValue().subject.length > 0 ? (' ' + selectedTicket$.getValue().subject | sanitizeHtml) : (' ' + selectedTicket$.getValue().getThreadTitle() | sanitizeHtml)"></label>
            </div>
            <div id="ticket-details-header-row-1">
              <div class="ticket-tag-wrapper">
                <!-- <label class="lbl-tag tag-red">Bug</label> -->
              </div>
              <label class="ticket-label-info">Erstellt von {{selectedTicket$.getValue().user.getFullName()}}, {{getValueDate(selectedTicket$.getValue().createdDate)}}</label>
            </div>
          </div>
        </div>
        <div id="ticket-details-body-inner">
          <div id="ticket-details-body-list">
            <div class="ticket-details-body-msg" *ngFor="let msg of selectedTicketMessages | slice:(selectedTicket$.getValue().subject != null && selectedTicket$.getValue().subject.length > 1 ? 0 : 1); let i = index">
              <div class="ticket-details-body-msg-line">
                <div class="msg-vertical-line" *ngIf="i < selectedTicketMessages.length-1"></div>
                <div class="msg-vertical-line-img">
                  <img [src]="msg.icon">
                </div>
                <div class="msg-horizontal-line"></div>
              </div>
              <img id="ticket-details-body-list-img" [src]="msg.user.getAvatar()"/>
              <div class="ticket-details-body-msg-content">
                <label [innerHTML]="msg.message | sanitizeHtml"></label>
                <div *ngIf="msg.attachment" class="message-images-container" [attr.data-message-id]="msg.messageId">
                  <img src="{{msg.attachment}}" (click)="openImage(msg.attachment)"/>
                </div>
                <a [href]="msg.attachment" download class="download-button" *ngIf="msg.attachment">
                  <img width="14px" src="./assets/download.svg">
                </a>
                <label class="ticket-label-info">von {{msg.user.getFullName()}}, {{getValueDate(msg.timestamp)}}</label>
              </div>
            </div>
          </div>
        </div>
        <div id="ticket-details-body-footer">
          <div class="ticket-details-richt-text-wrapper" *ngIf="selectedTicket$.getValue()?.resolved == null">
            <app-rich-text-editor
              [editorId]="1"
              [control]="ticketForm.controls['message']"
              [data]="users"
              [activateBold]="false"
              [activateItalic]="false"
              [activateUnderline]="false"
              [activateStrikeThrougth]="false"
              (imageDropped)="handleImageDropped($event)"
            ></app-rich-text-editor>
          </div>
          <div class="ticket-details-btn-wrapper">
            <button *ngIf="!selectedTicket$.getValue().resolved && !this.ticketForm.controls['message'].invalid" [disabled]="this.ticketForm.controls['message'].invalid" (click)="selectionCreateAnswer(true)">Antworten und auflösen</button>
            <button *ngIf="!selectedTicket$.getValue().resolved && this.ticketForm.controls['message'].invalid" (click)="resolve(selectedTicket$.getValue())">Auflösen</button>
            <button *ngIf="!selectedTicket$.getValue().resolved" [disabled]="this.ticketForm.controls['message'].invalid" (click)="selectionCreateAnswer(false)">Antworten</button>
            <button *ngIf="selectedTicket$.getValue().resolved" (click)="selectionRestore(selectedTicket$.getValue())">Wiederherstellen</button>
          </div>
        </div>
      </div>
      <div id="ticket-details-info" [class.ticket-details-info-active]="infoActive" *ngIf="!isPhone">
        <div class="ticket-details-info-section-no-border">
          <label class="ticket-label-info">#{{selectedTicket$.getValue().threadId}}</label>
        </div>
        <div class="ticket-details-info-section">
          <label class="ticket-label-info">Aktionen</label>
          <div class="ticket-details-section-wrapper">
            <button class="button-action" (click)="showEdit()">
              <img src="./assets/editticket.svg">
              Ticket bearbeiten
            </button>
          </div>
          <div class="ticket-details-section-wrapper">
            <button class="button-action" (click)="showMoveTicket = true">
              <img src="./assets/moveticket.svg">
              Ticket verschieben
            </button>
          </div>
        </div>
        <div class="ticket-details-info-section" *ngIf="false">
          <label class="ticket-label-info">Zurodnung</label>
          <label class="lbl-tag tag-red">Bug</label>
        </div>
        <div class="ticket-details-info-section">
          <label class="ticket-label-info">Ersteller</label>
          <div class="ticket-details-section-wrapper">
            <img id="ticket-details-body-list-img" [src]="selectedTicket$.getValue().user.getAvatar()"/>
            <label>{{selectedTicket$.getValue().user.getFullName()}}</label>
          </div>
        </div>
        <div class="ticket-details-info-section">
          <label class="ticket-label-info">Erstellt am</label>
          <label>{{getValueDate(selectedTicket$.getValue().createdDate)}}</label>
        </div>
        <div class="ticket-details-info-section">
          <label class="ticket-label-info">Gelöst am</label>
          <label>{{getValueDate(selectedTicket$.getValue().resolved)}}</label>
          <label>{{getValueString(selectedTicket$.getValue().resolved_by_user?.getFullName())}}</label>
        </div>
        <div class="ticket-details-info-section" *ngIf="false">
          <label class="ticket-label-info">Dringlichkeit</label>
          <label class="lbl-tag tag-red">Sofort</label>
        </div>
        <div class="ticket-details-info-section-no-border">
          <label class="ticket-label-info">Benachrichtigung an</label>
          <button class="button-action" (click)="selectionSubscription()">
            <img src="./assets/ticketunsubscribe.svg">
            {{selectedTicket$.getValue().subscribe ? 'Unsubscribe' : 'Subscribe'}}
          </button>
        </div>
      </div>
    </div>
    <div id="ticket-details-info-button" (click)="infoActive = !infoActive" *ngIf="selectedTicket$.getValue() != null && !isPhone">
      <img [class.ticket-details-info-button-icon-active]="infoActive" src="./assets/arrowdropdown.svg">
      <label>Ticket-Informationen</label>
    </div>
  </div>
</div>
<form id="ticket-create-container" *ngIf="isCreate" [formGroup]="ticketForm">
  <div class="ticket-create-sectiond">
    <label>Label</label>
    <ul class="ticket-types-list">
      <li *ngFor="let type of selectTypes">
        <button
          type="button"
          [ngClass]="{
            'todo': selectedType === 1 && type.value == selectedType,
            'feature': selectedType === 2 && type.value == selectedType,
            'bug': selectedType === 3 && type.value == selectedType,
            'doku-frage': selectedType === 4 && type.value == selectedType || selectedType === 5 && type.value == selectedType,
            'feature-intern': selectedType === 6 && type.value == selectedType
          }"
          (click)="onSelectType(type)">
          <span class="name">{{ type.label }}</span>
        </button>
      </li>
    </ul>

  </div>
  <div class="ticket-create-sectiond">
    <label>Betreff</label>
    <input type="text" class="ticket-input" [maxlength]="250" placeholder="Betreff" formControlName="subject" [required]="submitted && ticketForm.controls['subject'].invalid">
  </div>
  <div class="ticket-details-richt-text-wrapper">
    <label>Inhalt</label>
    <app-rich-text-editor
      [editorId]="2"
      [control]="ticketForm.controls['message']"
      [data]="users"
      [activateBold]="false"
      [activateItalic]="false"
      [activateUnderline]="false"
      [activateStrikeThrougth]="false"
      [openAnimate]="false"
      [maxChar]="500"
      (imageDropped)="handleImageDropped($event)"
    ></app-rich-text-editor>
  </div>
  <div class="ticket-details-btn-wrapper">
    <button [disabled]="this.ticketForm.get('subject').invalid" (click)="createTicket()">Erstellen</button>
  </div>
</form>

<app-dialog-filter-tickets *ngIf="showFilterTickets"
[filter]="filterValue"
[defaultFilter]="defaultFilter"
(closeTicketFilterEmitter)="closeFilterDialogAndFilter($event);"
(applyFilterEmitter)="applyFilterEmitter.emit();"
></app-dialog-filter-tickets>

<app-dialog-edit-ticket *ngIf="showEditTicket"
(closeTicketEditEmitter)="closeEditDialog();"
[ticket]="selectedTicket$.getValue()"
></app-dialog-edit-ticket>

<app-dialog-move-ticket *ngIf="showMoveTicket"
(closeTicketMoveEmitter)="closeMoveDialog();"
(updateCloseTicketMoveEmitter)="updateCloseMoveDialog($event);"
[tickets]="tickets"
[ticket]="selectedTicket$.getValue()"
></app-dialog-move-ticket>

<app-dialog-image-open *ngIf="showImageOpen"
(closeImageOpenEmitter)="closeImageDialog();"
[imageUrl]="selectedImageUrl"
></app-dialog-image-open>
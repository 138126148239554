
<div class="popup-body">

  <div class="popup-container">
    <button class="close_icon" (click)="selectionCloseEMitter.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 16.288 15.714">
        <g id="closecross" transform="translate(-130.312 -22.732)">
          <path class="close-svg" data-name="Pfad 2748" d="M0,0H17.223" transform="translate(132.08 24.5) rotate(45)"  stroke="#000" stroke-linecap="round" stroke-width="2.5"/>
          <path class="close-svg" data-name="Pfad 2750" d="M0,0H17.223" transform="translate(144.832 24.5) rotate(135)"  stroke="#000" stroke-linecap="round" stroke-width="2.5"/>
        </g>
      </svg>
    </button>

    <div *ngIf="showWindow === 'changePriority'" class="phonelist-change-popup">
      <div class="popup-header">
          Priorität ändern
      </div>
      <ul class="popup-priorities">
        <li *ngFor="let type of selectPriorities">
          <button
            type="button"
            [ngClass]="{
              'hoch': selectedPriority === 0 && type.value == selectedPriority,
              'mittel': selectedPriority === 1 && type.value == selectedPriority,
              'niedrig': selectedPriority === 2 && type.value == selectedPriority
            }"
            (click)="onSelectPriority(type.value)">
            <span class="name">{{ type.label }}</span>
          </button>
        </li>
      </ul>
        <button class="popup-footer" (click)="changePriority()">Speichern</button>
    </div>

    <div *ngIf="showWindow === 'changeDate'" class="phonelist-change-popup">
      <div *ngIf="!changeDateIndividually">
      <div class="popup-header">
          Anruf verschieben
      </div>
      <div class="inputs-wrapper">
        <!-- Auf Nachmittag verschieben -->
        <div class="button_with_input" *ngIf="selectedCall.time_type == TimeTyp.MORNING || exactDate.getHours() < 12 ">
          <div class="flex_slider">
            <div class="slider_container" (click)="changeDateToAfterNoon = !changeDateToAfterNoon">
              <div class="slider_still" [ngClass]="changeDateToAfterNoon ? '' : 'slider_still_on'"></div>
              <div class="slider_move" [ngClass]="changeDateToAfterNoon  ? 'slider_on' : 'slider_off'"></div>
            </div>
            <div [ngClass]="changeDateToAfterNoon ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Auf Nachmittag verschieben</div>
          </div>
        </div>
        <!-- Auf Morgen verschieben -->
        <div class="button_with_input" *ngIf="callIsToday && (selectedCall.time_type == TimeTyp.AFTERNOON || exactDate.getHours() >= 12)">
          <div class="flex_slider">
            <div class="slider_container" (click)="changeDateByOneDay = !changeDateByOneDay">
              <div class="slider_still" [ngClass]="changeDateByOneDay ? '' : 'slider_still_on'"></div>
              <div class="slider_move" [ngClass]="changeDateByOneDay  ? 'slider_on' : 'slider_off'"></div>
            </div>
            <div [ngClass]="changeDateByOneDay ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Auf morgen verschieben</div>
          </div>
        </div>
        <!-- Tage verschieben -->
        <div class="button_with_input">
          <div class="flex_slider">
            <div class="slider_container" (click)="changeDateByDays = !changeDateByDays">
              <div class="slider_still" [ngClass]="changeDateByDays ? '' : 'slider_still_on'"></div>
              <div class="slider_move" [ngClass]="changeDateByDays  ? 'slider_on' : 'slider_off'"></div>
            </div>
            <div [ngClass]="changeDateByDays ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Tage verschieben</div>
          </div>
          <input class="input_field" type="text" [(ngModel)]="daysToShift">
        </div>
        <!-- Wochen verschieben -->
        <div class="button_with_input">
          <div class="flex_slider">
            <div class="slider_container" (click)="changeDateByWeek = !changeDateByWeek">
              <div class="slider_still" [ngClass]="changeDateByWeek ? '' : 'slider_still_on'"></div>
              <div class="slider_move" [ngClass]="changeDateByWeek  ? 'slider_on' : 'slider_off'"></div>
            </div>
            <div [ngClass]="changeDateByWeek ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Wochen verschieben</div>
          </div>
          <input class="input_field" type="text" [(ngModel)]="weeksToShift">
        </div>
        <!-- Individuell verschieben -->
        <div class="button_with_input">
          <div class="flex_slider">
            <div class="slider_container" (click)="changeDateIndividually = !changeDateIndividually">
              <div class="slider_still" [ngClass]="changeDateIndividually ? '' : 'slider_still_on'"></div>
              <div class="slider_move" [ngClass]="changeDateIndividually  ? 'slider_on' : 'slider_off'"></div>
            </div>
            <div [ngClass]="changeDateIndividually ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Individuell verschieben</div>
          </div>
        </div>
      </div>
    </div>

      <!-- Individuell verschieben -->
      <div class="popup-header-2"  *ngIf="changeDateIndividually">
        <button class="button_icon_lbl" (click)="changeDateIndividually = !changeDateIndividually">
          <img src="../../../../../assets/navarrow.svg" style="rotate: 90deg;">
          Zurück
        </button>
        Individuell verschieben
      </div>
      <div class="individual_date" *ngIf="changeDateIndividually">
        <form [formGroup]="form">
          <!-- Datum wählen -->
          <div class="input_wrapper_planning">
            <label class="lbl_regular_12">Datum wählen</label>
            <input #inputDate1
              type="date"
              id="input-due-date"
              [min]="minDate"
              formControlName="due_date"
              [class.date-input--has-value]="inputDate1.value != null">
          </div>
          <!-- Uhrzeit wählen -->
          <div class="input_wrapper_planning">
            <div class="label_with_toggle_button">
              <label class="lbl_regular_12">Uhrzeit wählen (optional)</label>
              <div class="flex_slider_label">
                <div class="slider_container" (click)="selectionTimeTypExact()">
                  <div class="slider_still" [ngClass]="form.get('time_type').value == TimeTyp.EXACT ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="form.get('time_type').value == TimeTyp.EXACT ? 'slider_on' : 'slider_off'"></div>
                </div>
                <div [ngClass]="form.get('time_type').value == TimeTyp.EXACT ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0px;">Individuelle Zeit definieren</div>
              </div>
            </div>
            <div *ngIf="this.form.get('time_type').value != TimeTyp.EXACT" class="dropdown_wrapper" (click)="showDropdown = !showDropdown" appDropdown [show]="showDropdown" (changedValueEmitter)="showDropdown = $event">
              <input #inputTime placeholder="---" [value]="form.get('time_type').value == TimeTyp.MORNING ? 'Vormittag' : 'Nachmittag'" readonly>
              <img class="dropdown_icon" [ngClass]="showDropdown ? 'dropdown_icon_open' : ''" src="./assets/arrowdropdown.svg">
              <div class="dropdown_value_container" *ngIf="showDropdown">
                <label (click)="setTimeTyp('morning')" class="dropdown_element lbl_dropdown_small">Vormittag</label>
                <label (click)="setTimeTyp('afternoon')" class="dropdown_element lbl_dropdown_small">Nachmittag</label>
              </div>
            </div>
            <div *ngIf="this.form.get('time_type').value == TimeTyp.EXACT" class="input_wrapper">
              <input #inputDate2 type="time" placeholder="---" formControlName="time">
            </div>
          </div>
        </form>
      </div>
      <button class="popup-footer" (click)="changeDate()">Verschieben</button>
    </div>
  </div>
</div>

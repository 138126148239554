<div id="sidebar_customers" [ngClass]="minimizeSidebar ? 'sidebar_customers_minimized' : 'sidebar_customers_open'">
  <div id="sidebar_list">
    <div id="sidebar_customer_filter">
      <div id="sidebar_customer_filter_row_2">
        <input #search class="inputfield_search lbl_input" type="text" placeholder="Kunden durchsuchen" (input)="searchAction(search.value)">
      </div>
      <div id="sidebar_customer_filter_row_1">
        <div class="sidebar_filter_flex">
          <label class="lbl_regular_14">{{customers.length}} Kunden {{getSortInfo()}}</label>
          <div class="flex">
            <div id="sort_menu">
              <div class="action_menu_button">
                <img src="../../../assets/filter.svg">
              </div>
              <div id="action_menu_wrapper">
                <div class="action_menu_item" (click)="showFilterStatus = true">
                  <label class="lbl_regular_14">Status</label>
                </div>
                <div class="action_menu_item" (click)="showFilterUser = true">
                  <label class="lbl_regular_14">Seller</label>
                </div>
                <div class="action_menu_item" (click)="showFilterClassification = true">
                  <label class="lbl_regular_14">Zuordnung</label>
                </div>
              </div>
            </div>
            <div id="sort_menu">
              <div class="action_menu_button">
                <img src="../../../assets/navdots.svg">
              </div>
              <div id="action_menu_wrapper">
                <div class="action_menu_item" [ngClass]="filter.sort_after == 1 ? 'action_menu_item_active' : ''" (click)="filter.sort_after = 1; filterEmitter.emit()">
                  <label class="lbl_regular_14">Alphabetisch</label>
                </div>
                <div class="action_menu_item" [ngClass]="filter.sort_after == 2 ? 'action_menu_item_active' : ''"  (click)="filter.sort_after = 2; filterEmitter.emit()">
                  <label class="lbl_regular_14">Letzter Termin</label>
                </div>
                <div class="action_menu_item" [ngClass]="filter.sort_after == 3 ? 'action_menu_item_active' : ''"  (click)="filter.sort_after = 3; filterEmitter.emit()">
                  <label class="lbl_regular_14">PLZ</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="setted_filter_wrapper">
        <div *ngFor="let user of filter.users">
          <label class="lbl_filter">Seller: {{user.firstName + ' ' + user.lastName}}<button (click)="removeFilterSellerEmitter.emit(user)">&#10005;</button></label>
        </div>
        <div *ngFor="let status of filter.status">
          <label class="lbl_filter">Status: {{status == 1 ? 'Kunde' : (status == 2 ? 'Interessent' : 'Archiviert')}}<button (click)="removeFilterStatusEmitter.emit(status)">&#10005;</button></label>
        </div>
        <div *ngFor="let classification of filter.classification">
          <label class="lbl_filter">Zuordnung: 
              {{classification == 1 ? 'Waymark' : 
              (classification == 2 ? 'Zweispurig' : 
              (classification == 3 ? 'Autobase' : 
              (classification == 4 ? 'Autoscout' : ''))) }}
            <button (click)="removeFilterClassificationEmitter.emit(classification)">&#10005;</button>
          </label>
        </div>
      </div>
    </div>
    <div id="sidebar_customer_list">
      <app-customer-sidebar-element *ngFor="let customer of customers"
        class="customer_sidebar_elements"
        [attr.id]="'customer_'+customer.customer_id"
        [customer]="customer"
        [paraCustomerId]="paraCustomerId"
      ></app-customer-sidebar-element>
    </div>
  </div>
  <button class="button_minimize_sidebar" [class.button_minimize_sidebar_closed]="minimizeSidebar" (click)="minimizeEmitter.emit()">
    <img class="button_minimize_sidebar_icon" [ngClass]="minimizeSidebar ? 'button_minimize_sidebar_icon_closed' : ''" src="../../../../assets/arrowdropdown-grey.svg">
  </button>
</div>

<app-dialog-filter-user *ngIf="showFilterUser"
  [filter]="filter"
  [users]="users"
  (closeEmitter)="showFilterUser = false"
  (applyFilterEmitter)="applyFilterEmitter.emit(); showFilterUser = false;"
></app-dialog-filter-user>
<app-dialog-filter-status *ngIf="showFilterStatus"
  [filter]="filter"
  (closeEmitter)="showFilterStatus = false"
  (applyFilterEmitter)="applyFilterEmitter.emit(); showFilterStatus = false;"
></app-dialog-filter-status>
<app-dialog-filter-classification *ngIf="showFilterClassification"
  [filter]="filter"
  (closeEmitter)="showFilterClassification = false"
  (applyFilterEmitter)="applyFilterEmitter.emit(); showFilterClassification = false;"
></app-dialog-filter-classification>

import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UpdateService } from './service/update/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'waymark-crm';
  isOnline: boolean;
  isTest = false;
  version = environment.VERSION;

  constructor(
    //private updateService: UpdateService,
  ){}

  ngOnInit(): void {
    if(environment.api.includes('test')) this.isTest = true;
    this.calculateViewDimension();
  }

  showMenu(outlet: RouterOutlet): boolean {
    if(!outlet?.activatedRouteData?.['isLogin']){
      return true;
    }
    return false;
  }

  //calculating values for phone dimensions
  calculateViewDimension() {
    var px_height = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--calc-vh', px_height + 'px');
    var px_width = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--calc-vw', px_width + 'px');
  }
}

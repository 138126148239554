<div id="ticket-create-container">


  <!-- DROPDOWN BUTTON TICKET ERSTELLEN -->
  <form class="ticket-form" *ngIf="ticketForm" [formGroup]="ticketForm">
    <div class="dropdowns">
      <label>Kunde wählen</label>
      <label>Projekt wählen</label>
      <div class="dropdown_wrapper" (click)="showDropdown = !showDropdown" appDropdown [show]="showDropdown" (changedValueEmitter)="showDropdown = $event">
        <input #inputCustomer placeholder="---" id="input-customer" (input)="searchCustomers(inputCustomer.value)">
        <img class="dropdown_icon" [ngClass]="showDropdown ? 'dropdown_icon_open' : ''" src="./assets/arrowdropdown.svg">
        <div class="dropdown_value_container" *ngIf="showDropdown">
          <div *ngFor="let customer of customersFiltered">
            <label *ngIf="customer.company_name"
            (click)="selectionCustomer(customer); inputCustomer.value = (customer.company_name)" class="dropdown_element lbl_dropdown_small">
                {{customer.company_name}}
            </label>
          </div>
        </div>
      </div>
      <!-- <div [class.input-wrapper-disabled]="ticketForm.get('customer_id').value == null" class="dropdown_wrapper" (click)="showDropdown2 = !showDropdown2" appDropdown [show]="showDropdown2" (changedValueEmitter)="showDropdown2 = $event">
        <input #inputProject placeholder="---" id="input-project">
        <img class="dropdown_icon" [ngClass]="showDropdown2 ? 'dropdown_icon_open' : '---'" src="./assets/arrowdropdown.svg">
        <div class="dropdown_value_container" *ngIf="showDropdown2">
          <div *ngFor="let project of projects">
            <label
              (click)="selectionProject(project);  inputProject.value = (project.awork_project.name)" class="dropdown_element lbl_dropdown_small">
                {{project.awork_project.name}}
            </label>
          </div>
        </div>
      </div> -->
      <div class="input_wrapper" [class.input-wrapper-disabled]="ticketForm.get('customer_id').value == null">
        <div class="input_wrapper" [class.input-wrapper-disabled]="ticketForm.get('customer_id').value == null">
          <div class="dropdown_wrapper" (click)="showDropdown2 = !showDropdown2" appDropdown [show]="showDropdown2" (changedValueEmitter)="showDropdown2 = $event">
            <input #inputProject autocomplete="off" placeholder="---" id="input-project" [disabled]="ticketForm.get('customer_id').value == null">
            <img class="dropdown_icon" *ngIf="ticketForm.get('customer').value" [ngClass]="showDropdown2 ? 'dropdown_icon_open' : ''" src="./assets/arrowdropdown.svg">
            <div class="dropdown_value_container" *ngIf="showDropdown2 &&  ticketForm.get('customer_id').value != null">
              <div *ngFor="let project of projects">
                <label
                  (click)="selectionProject(project);  inputProject.value = (project.awork_project.name)" class="dropdown_element lbl_dropdown_small">
                    {{project.awork_project.name}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- TICKET FORM -->
  <form class="ticket-form" id="ticket-form-wrapper" [formGroup]="ticketForm">
    <div class="ticket-create-sectiond">
      <label>Label</label>
      <ul class="ticket-types-list">
        <li *ngFor="let type of selectTypes">
          <button
            type="button"
            [ngClass]="{
              'todo': selectedType === 1 && type.value == selectedType,
              'feature': selectedType === 2 && type.value == selectedType,
              'bug': selectedType === 3 && type.value == selectedType,
              'doku-frage': selectedType === 4 && type.value == selectedType || selectedType === 5 && type.value == selectedType,
              'feature-intern': selectedType === 6 && type.value == selectedType
            }"
            (click)="onSelectType(type)">
            <span class="name">{{ type.label }}</span>
          </button>
        </li>
      </ul>
    </div>
    <div class="ticket-create-sectiond">
      <label>Betreff</label>
      <input type="text" class="ticket-input" [maxlength]="250" placeholder="Betreff" formControlName="subject" [required]="submitted && ticketForm.controls['subject'].invalid">
    </div>
    <div class="ticket-details-richt-text-wrapper">
      <label>Inhalt</label>
      <app-rich-text-editor
        [editorId]="2"
        [control]="ticketForm.controls['message']"
        [data]="users"
        [activateBold]="false"
        [activateItalic]="false"
        [activateUnderline]="false"
        [activateStrikeThrougth]="false"
        [openAnimate]="false"
        [maxChar]="500"
        (imageDropped)="handleImageDropped($event)"
        [chosenText]="call.driver_note"
      ></app-rich-text-editor>
    </div>
    <div class="ticket-details-btn-wrapper">
      <button (click)="ticketCreatedEmitter.emit(true)">Auf erledigt setzen</button>
      <button [disabled]="this.ticketForm.get('subject').invalid" (click)="createTicket()">Erstellen</button>
    </div>
  </form>
</div>

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Call, Priority, TIMETYPE } from 'src/app/buisness-object/call/Call';
import { CallForm } from 'src/app/buisness-object/call/form/CallForm';
import { PhonelistActionMenuComponent } from '../phonelist-action-menu/phonelist-action-menu.component';
import { PhoneCallService } from 'src/app/service/phone-call/phone-call.service';
import { catchError, firstValueFrom, lastValueFrom, map, min, Observable } from 'rxjs';
import { formatDate, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CloneObject } from 'src/app/helper/CloneObject';

@Component({
  selector: 'app-phonelist-planning',
  templateUrl: './phonelist-planning.component.html',
  styleUrls: ['./phonelist-planning.component.scss']
})
export class PhonelistPlanningComponent implements OnInit {

  @Input() calls: Call[];
  @Input() timeStampSets: any;
  @Output() switchViewListEmitter = new EventEmitter<any>();
  @Output() switchViewCreateEmitter = new EventEmitter<any>();
  @Output() editEmitter = new EventEmitter<Call>();
  @Output() deleteCallEmitter = new EventEmitter<Call>();
  @Output() updateCallEmitter = new EventEmitter<Call>();

  public form: FormGroup;
  public callsBeforeNoon;
  public callsAfterNoon;
  public hideList = true;
  public hideList2 = true;
  public hideList3 = true;
  public hideList4 = true;
  public hideList5 = true;
  public hideList6 = true;
  public currentDate: string = new Date().toISOString().split('T')[0];
  public displayDate: string = 'Heute';
  public showPopup = false;
  public popupWindow: string;
  public selectedCall;
  public draggedItemIndex: number = -1;
  public draggedItem: {
    call: Call,
    column: 'morning' | 'afternoon',
    priority: 'low' | 'middle' | 'high'
  };
  public draggedOverItem: {
    call: Call,
    column: 'morning' | 'afternoon',
    priority: 'low' | 'middle' | 'high'
  };
  public orderedCalls = [];
  public invalidDate: boolean = false;
  public minDate;
  public popupCallSelected;

  constructor(
    private formbuilder: FormBuilder,
    private phoneCallService: PhoneCallService,
    private router: Router,
    private location: Location,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.form = CallForm.getForm(this.formbuilder);
    this.setCustomerLinks();
    this.orderCalls();
    this.minDate = formatDate(new Date(),'yyyy-MM-dd','de');
  }

  initView(calls: Call[]) {
    this.calls = calls;
    this.orderCalls();
  }

  setCustomerLinks() {
    const baseUrl = `${window.location.origin}${this.location.prepareExternalUrl(this.router.url)}`;
    const rootPath = baseUrl.split('#')[0] + '/#/';
    for(let call of this.calls){
      if(call.customer_id){
        call.customer_link = `${rootPath}customers?customerId=${call.customer_id}`;
      }
    }
  }

  filterByPriority(calls: Call[], priority: number) {
    return calls.filter(call => call.priority === priority);
  }

  openEditView(call: Call) {
    this.editEmitter.emit(call);
  }

  deleteCall(call: Call) {
    this.phoneCallService.deletePhoneCall(call.call_id).subscribe((success) => {
      if(success) {
        const index = this.calls.findIndex((c) => c.call_id == call.call_id);
        if(index > -1){
          this.calls.splice(index, 1);
          this.orderCalls();
        }
        this.deleteCallEmitter.emit(call);
      }
    })
  }

  changeDate(days: number): void {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const date = new Date(this.currentDate);
    date.setDate(date.getDate() + days);

    if (date < today) {
      return;
    }

    this.currentDate = date.toISOString().split('T')[0];
    this.updateDisplayDate(date);
  }


  onDateInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const date = new Date(input.value);
    this.currentDate = input.value;
    this.updateDisplayDate(date);
  }

  updateDisplayDate(date: Date): void {
    const today = new Date();
    if (date.toDateString() === today.toDateString()) {
      this.displayDate = 'Heute';
    } else {
      this.displayDate = date.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    }
    this.orderedCalls =[];
    this.orderCalls();
  }

  showPopupWindow(window: string, call: Call) {
    this.popupWindow = window;
    this.popupCallSelected = CloneObject.deepCopy(call);
    this.showPopup = true;
  }

  onDragOver(event: DragEvent, item: Call, column: 'morning' | 'afternoon', priority: 'low' | 'middle' | 'high') {
    event.preventDefault();
    if(item){
      this.draggedOverItem = {
        call: item,
        column: column,
        priority: priority
      }
    } else {
      this.draggedOverItem.column = column;
      this.draggedOverItem.priority = priority;
    }
  }

  onDragStart(event: DragEvent, item: Call, column: 'morning' | 'afternoon', priority: 'low' | 'middle' | 'high'){
    //event.preventDefault();
    event.stopPropagation();
    event.dataTransfer?.setData('text/plain', JSON.stringify({ item, column, priority }));
    event.dataTransfer!.effectAllowed = "move";
    this.cdRef.detectChanges();
    this.draggedOverItem = {
      call: item,
      column: column,
      priority: priority
    }
    this.draggedItem = {
      call: item,
      column: column,
      priority: priority
    }
  }

  async onDrop(event: DragEvent, column: 'morning' | 'afternoon', priority: 'low' | 'middle' | 'high') {
    event.preventDefault();
    if(this.draggedOverItem?.call?.call_id == this.draggedItem.call.call_id &&
      this.draggedOverItem?.priority == this.draggedItem.priority &&
      this.draggedOverItem?.column == this.draggedItem.column
    ){
      return;
    }
    this.draggedItem.call.priority = this.getOndropPriority(priority);
    this.draggedItem.call.time_type = this.getOnDropTimeTyp(column);
    this.replaceDraggedItem(column, priority);
    this.draggedItem = null;
    this.draggedOverItem = null;
  }

  async replaceDraggedItem(column: 'morning' | 'afternoon', priority: 'low' | 'middle' | 'high') {
    // Determine target block and priority
    const targetCallBlock = column === 'morning' ? this.orderedCalls[0] : this.orderedCalls[1];
    const targetCallPriority =
      priority === 'high'
        ? targetCallBlock[0]
        : (priority === 'middle' ? targetCallBlock[1] : targetCallBlock[2]);
    const isDraggeItemInTargetCallBlock = targetCallPriority.calls.find((c) => c.call_id == this.draggedItem.call.call_id);

    if(isDraggeItemInTargetCallBlock){
      // Handle moving item in same block
      if(targetCallPriority.calls.length > 1) {

        const draggedIndex = targetCallPriority.calls.findIndex((c) => c.call_id === this.draggedItem.call.call_id);
        const targetIndex = targetCallPriority.calls.findIndex((c) => c.call_id === this.draggedOverItem.call.call_id);

        const baseTimestamp = new Date(this.currentDate).setHours(0, 0, 0, 0);
        const hoveredTimestamp = targetCallPriority.calls[targetIndex]?.due_date || 0;
        const rangeStart = hoveredTimestamp - baseTimestamp + 1;
        const rangeEnd = rangeStart + (targetIndex > draggedIndex ? 60000 : -60000);

        const timestampSets = this.generateTimestampSets(targetCallPriority.calls);
        const newTimestamp = this.generateUniqueTimestamp(
          new Date(this.currentDate),
          rangeStart,
          rangeEnd,
          column === 'morning' ? timestampSets.morningSet : timestampSets.afternoonSet
        );

        this.draggedItem.call.due_date = newTimestamp;

        if (draggedIndex === -1) {
          targetCallPriority.calls.push(this.draggedItem.call);
        }

        const minTimestamp = new Date(this.currentDate).setHours(column === 'morning' ? 6 : 12, 0, 0, 0);
        const maxTimestamp = new Date(this.currentDate).setHours(column === 'morning' ? 12 : 18, 0, 0, 0);

        targetCallPriority.calls.sort((a, b) => a.due_date - b.due_date);

        let previousTimestamp = minTimestamp;
        targetCallPriority.calls.forEach((call) => {
          if(call.time_type != TIMETYPE.EXACT){
            let newCallTimestamp = previousTimestamp + 60000;
            if (newCallTimestamp > maxTimestamp) {
              newCallTimestamp = maxTimestamp;
            }
            call.due_date = newCallTimestamp;
            previousTimestamp = newCallTimestamp;
          }
        });
      } else {
        // If target block is empty, simply add the dragged item
        if(targetCallPriority[0] && targetCallPriority[0].call_id != this.draggedItem.call.call_id){
          targetCallPriority.calls.push(this.draggedItem.call);
        }
      }
    } else {
      // Handle moving item to a different block
      let indexBlock = this.draggedItem.column == 'morning' ? 0 : 1;
      let indexPriority = this.draggedItem.priority == 'high' ? 0 : (this.draggedItem.priority == 'middle' ? 1 : 2);
      let index = this.orderedCalls[indexBlock][indexPriority].calls.findIndex((c) => c.call_id == this.draggedItem.call.call_id);
      if(index !== -1){
        this.orderedCalls[indexBlock][indexPriority].calls.splice(index, 1);
      }

      if(targetCallPriority.calls.length > 1) {
        const targetIndex = targetCallPriority.calls.findIndex((c) => c.call_id === this.draggedOverItem.call.call_id);
        const baseTimestamp = new Date(targetCallPriority.calls[0]?.due_date).setHours(0, 0, 0, 0);
        const hoveredTimestamp = targetCallPriority.calls[targetIndex]?.due_date || 0;

        const rangeStart = hoveredTimestamp - baseTimestamp + 1;
        const rangeEnd = rangeStart + (targetIndex > -1 ? 60000 : -60000);

        const timestampSets = this.generateTimestampSets(targetCallPriority.calls);
        const newTimestamp = this.generateUniqueTimestamp(
          new Date(baseTimestamp),
          rangeStart,
          rangeEnd,
          column === 'morning' ? timestampSets.morningSet : timestampSets.afternoonSet
        );

        // Assign new timestamp and add to target block
        this.draggedItem.call.due_date = newTimestamp;
        targetCallPriority.calls.push(this.draggedItem.call);


        const minTimestamp = new Date(this.currentDate).setHours(column === 'morning' ? 6 : 12, 0, 0, 0);
        const maxTimestamp = new Date(this.currentDate).setHours(column === 'morning' ? 12 : 18, 0, 0, 0);

        targetCallPriority.calls.sort((a, b) => a.due_date - b.due_date);

        let previousTimestamp = minTimestamp;
        targetCallPriority.calls.forEach((call) => {
          if(call.time_type != TIMETYPE.EXACT){
            let newCallTimestamp = previousTimestamp + 60000;
            if (newCallTimestamp > maxTimestamp) {
              newCallTimestamp = maxTimestamp;
            }
            call.due_date = newCallTimestamp;
            previousTimestamp = newCallTimestamp;
          }
        });
      } else {
        // New block is empty, assign a default timestamp
        const baseTimestamp = new Date(this.currentDate).setHours(column === 'morning' ? 6 : 12, 0, 0, 0);
        this.draggedItem.call.due_date = baseTimestamp;

        targetCallPriority.calls.push(this.draggedItem.call);

        // const minTimestamp = new Date(this.currentDate).setHours(column === 'morning' ? 6 : 12, 0, 0, 0);
        // const maxTimestamp = new Date(this.currentDate).setHours(column === 'morning' ? 12 : 18, 0, 0, 0);

        // targetCallPriority.calls.sort((a, b) => a.due_date - b.due_date);

        // let previousTimestamp = minTimestamp;
        // targetCallPriority.calls.forEach((call) => {
        //   if(call.time_type != TIMETYPE.EXACT){
        //     let newCallTimestamp = previousTimestamp + 60000;
        //     if (newCallTimestamp > maxTimestamp) {
        //       newCallTimestamp = maxTimestamp;
        //     }
        //     call.due_date = newCallTimestamp;
        //     previousTimestamp = newCallTimestamp;
        //   }
        // });
      }
    }
    targetCallPriority.calls.sort((a, b) => a.due_date - b.due_date);
    const updateDone = await this.updateCallsInBackend(targetCallPriority.calls);
  }

  async updateCallsInBackend(calls: Call[]) {
    let promises: Promise<any>[] = [];
    for(let call of calls){
      promises.push(lastValueFrom(this.updateCall(call)));
    }
    await Promise.all(promises).then(() => {
      return true;
    });
  }

  updateCall(call: Call): Observable<Call> {
    return this.phoneCallService.updatePhoneCall(call).pipe(
      map((result) => {
        return result;
      }, catchError((error) => {
        return null;
      }))
    )
  }

  generateTimestampSets(calls: Call[]) {
    const morningSet = new Set<number>();
    const afternoonSet = new Set<number>();
    for(const call of calls) {
      if (call.time_type == TIMETYPE.MORNING) {
        morningSet.add(call.due_date);
      } else if (call.time_type == TIMETYPE.AFTERNOON) {
        afternoonSet.add(call.due_date);
      }
    }
    return { morningSet, afternoonSet };
  }

  generateUniqueTimestamp(
    date: Date,
    rangeStart: number,
    rangeEnd: number,
    existingTimestamps: Set<number>
  ): number | undefined {
    const baseTimestamp = date.setHours(0, 0, 0, 0);
    const step = 5 * 60 * 1000;

    let minOffset = Math.min(rangeStart, rangeEnd);
    let maxOffset = Math.max(rangeStart, rangeEnd);

    while (minOffset <= maxOffset) {
      for(let offset = minOffset; offset <= maxOffset; offset += step) {
        const candidate = baseTimestamp + offset;
        if (!existingTimestamps.has(candidate)) {
          return candidate;
        }
      }

      minOffset -= step; // Expand backward
      maxOffset += step; // Expand forward
      console.warn('Expanding search range:', { minOffset, maxOffset });
    }
    console.error('No valid timestamp found after expanding range:', { rangeStart, rangeEnd });
    return undefined;
  }

  removeOrderedCall(item: any) {
    let indexBlock = item.column == 'morning' ? 0 : 1;
    let indexPriority = item.priority == 'high' ? 0 : (item.priority == 'middle' ? 1 : 2);
    let index = this.orderedCalls[indexBlock][indexPriority].calls.findIndex((c) => c.call_id == item.call.call_id);
    if(index != -1){
      this.orderedCalls[indexBlock][indexPriority].calls.splice(index, 1);
    }
  }

  getOndropPriority(priority: 'low' | 'middle' | 'high'){
    switch (priority) {
      case 'high': return Priority.HIGH; break;
      case 'middle': return Priority.MIDDLE; break;
      case 'low': return Priority.LOW; break;
      default: break;
    }
  }

  getOnDropTimeTyp(column: 'morning' | 'afternoon'): number {
    return column == 'morning' ? TIMETYPE.MORNING : TIMETYPE.AFTERNOON
  }



  // getNewTime(column: 'morning' | 'afternoon'): number {
  //   let dueDate = new Date(this.draggedItem.call.due_date);
  //   switch (column) {
  //     case 'morning':
  //       dueDate.setHours(11);
  //       dueDate.setMinutes(59);
  //       break;
  //     case 'afternoon':
  //       dueDate.setHours(23);
  //       dueDate.setMinutes(59);
  //       break;
  //     default: break;
  //   }
  //   return dueDate.getTime();
  // }

  addOrderedCall(column: 'morning' | 'afternoon', priority: 'low' | 'middle' | 'high') {
    let indexBlock = column == 'morning' ? 0 : 1;
    let indexPriority = priority == 'high' ? 0 : (priority == 'middle' ? 1 : 2);
    this.orderedCalls[indexBlock][indexPriority].calls.push(this.draggedItem.call);
    this.orderedCalls[indexBlock][indexPriority].calls.sort((a, b) => a.due_date - b.due_date);
  }

  getToReplaceCallTime(column: 'morning' | 'afternoon', priority: 'low' | 'middle' | 'high') {

  }

  getDropIndex(target: HTMLElement) : number {
    const children = Array.from(target.parentElement?.children || []);
    return children.indexOf(target);
  }

  closeAndUpdatePopup(call: Call) {
    let index = this.calls.findIndex((c) => c.call_id == call.call_id);
    if(index > -1){
      this.calls[index] = call;
    }
    this.orderCalls();
    this.updateCallEmitter.emit(call);
    this.showPopup = false;
  }

  closePopup() {
    this.showPopup = false;
  }

  orderCalls() {
    this.orderedCalls = [];
    let priorities = [Priority.HIGH,Priority.MIDDLE,Priority.LOW];
    const selectedDateCall = this.calls.filter(call => {
      const dueDate = new Date(call.due_date);
      const isInDate = this.isSelectedDate(dueDate);
      return isInDate;
    })
    let vormittagCalls = [];
    const callVormittag = selectedDateCall.filter(call => {
      const dueDate = new Date(call.due_date);
      const isVormittag = this.isVormittag(dueDate);
      return isVormittag;
    })
    for(let p of priorities){
      const priorityCalls = callVormittag.filter(call => call.priority == p);
      priorityCalls.sort((a, b) => a.due_date - b.due_date);
      let block = {
        calls: priorityCalls,
        show: true
      }
      vormittagCalls.push(block)
    }
    let nachmittagsCalls = [];
    const callsNachmittag = selectedDateCall.filter(call => {
      const dueDate = new Date(call.due_date);
      const isNachmittag = this.isNachmittag(dueDate);
      return isNachmittag;
    })
    for(let p of priorities){
      const priorityCalls = callsNachmittag.filter(call => call.priority == p)
      priorityCalls.sort((a, b) => a.due_date - b.due_date);
      let block = {
        calls: priorityCalls,
        show: true
      }
      nachmittagsCalls.push(block)
    }
    this.orderedCalls.push(vormittagCalls);
    this.orderedCalls.push(nachmittagsCalls);
  }

  isSelectedDate(date: Date): boolean {
    const selectedDate = new Date(this.currentDate);
    return date.getFullYear() == selectedDate.getFullYear() &&
      date.getMonth() == selectedDate.getMonth() &&
      date.getDate() == selectedDate.getDate();
  }

  isVormittag(date: Date): boolean {
    return date.getHours() <= 11 && date.getHours() >= 0;
  }

  isNachmittag(date: Date): boolean {
    return date.getHours() >= 12;
  }

  sortOrderedPriorityCalls(calls: Call[]): Call[] {
    let hours = 0;
    let mins = 0;
    let i = 0;
    for(let call of calls){
      // i++;
      // call.order_nr = i;
      if(!call.time_type){
        let dueTime = new Date(call.due_date);
        dueTime.setHours(hours);
        dueTime.setMinutes(mins);
        call.due_date = dueTime.getTime();
        mins += 5;
        if(mins > 59){
          mins = 0;
          hours+=1;
        }
        if(hours > 11){
          break;
        }
      }
    }
    return calls;
  }

  selectionMove(call: Call) {

  }
}

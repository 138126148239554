import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { Call, TIMETYPE } from 'src/app/buisness-object/call/Call';
import { CallForm } from 'src/app/buisness-object/call/form/CallForm';
import { ENUMSTATUS } from 'src/app/helper/Enums';
import { PhoneCallService } from 'src/app/service/phone-call/phone-call.service';
import { DateHelper } from 'src/app/utils/DateHelper';

@Component({
  selector: 'app-phonelist-popup',
  templateUrl: './phonelist-popup.component.html',
  styleUrls: ['./phonelist-popup.component.scss']
})
export class PhonelistPopupComponent implements OnInit {

  @Input() selectedCall: Call;
  @Input() showWindow: string;
  @Input() timeStampSets: any;
  @Output() selectionSaveEmitter = new EventEmitter<any>();
  @Output() selectionCloseEMitter = new EventEmitter<any>();

  public form: FormGroup;
  public selectPriorities = [
    { label: 'Hoch', value: 0 },
    { label: 'Mittel', value: 1 },
    { label: 'Niedrig', value: 2 }
  ];
  public selectedPriority: number;
  public isMorning: boolean = false;
  public changeDateToAfterNoon: boolean = false;
  public changeDateByOneDay: boolean = false;
  public changeDateByDays: boolean = false;
  public changeDateByWeek: boolean = false;
  public changeDateIndividually: boolean = false;
  public daysToShift: number = 1;
  public weeksToShift: number = 1;
  public minDate;
  public TimeTyp = TIMETYPE;
  public exactDate;
  public timeChanged: boolean = false;
  public currentTime: string;
  public callIsToday = false;
  public showDropdown = false;

  constructor(
    private formbuilder: FormBuilder,
    private phoneCallService: PhoneCallService
  ) { }

  ngOnInit(): void {
    const callDate = new Date(this.selectedCall.due_date);
    const today = new Date();
    this.callIsToday = callDate.getFullYear() == today.getFullYear() && callDate.getMonth() == today.getMonth() && callDate.getDate() == today.getDate();
    this.form = CallForm.getForm(this.formbuilder, this.selectedCall);
    this.exactDate = new Date(this.selectedCall.due_date);
    let now = new Date();
    this.currentTime = DateHelper.getFormatTimeFromTimestamp(now.getTime());
    this.form.get('time').setValue(null);
    this.setTimeTyp('morning')
    this.selectedPriority = this.selectPriorities[this.selectedCall.priority].value;
    this.minDate = formatDate(new Date(),'yyyy-MM-dd','de');
    this.form.get('time').valueChanges.subscribe(value => {
      if(value) {
        this.timeChanged = true;
        this.selectedCall.time_type = TIMETYPE.EXACT;
      }
    });
  }

  onSelectPriority(value: number): void {
    this.selectedPriority = value;
    this.selectedCall.priority = value;
  }

  async changePriority()
  {
    await lastValueFrom(this.phoneCallService.updatePhoneCall(this.selectedCall));
    this.selectionSaveEmitter.emit(this.selectedCall)
  }

  changeToAfternoon() {
    const updatedDate = new Date(this.selectedCall.due_date);
    updatedDate.setHours(16, 0, 0, 0);
    this.selectedCall.due_date = updatedDate.getTime();
    this.selectedCall.time_type = this.TimeTyp.AFTERNOON
  }

  changeToTomorrow() {
    const oneDay = 24 * 60 * 60 * 1000;
    let date = new Date();
    date.setDate(date.getDate()+1);
    date.setHours(8)
    date.setMinutes(0)
    this.selectedCall.time_type = TIMETYPE.MORNING;
    this.selectedCall.due_date = date.getTime();
  }

  changeByDays(){
    const oneDay = 24 * 60 * 60 * 1000;
    this.selectedCall.due_date += this.daysToShift * oneDay;
    this.daysToShift = 0;
  }

  changeByWeeks(){
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    this.selectedCall.due_date += this.weeksToShift * oneWeek;
    this.weeksToShift = 0;
  }

  async changeDate(){
    // change to afternoon
    if(this.changeDateToAfterNoon && !this.changeDateByDays && !this.changeDateByWeek && !this.changeDateIndividually)
    {
      this.changeToAfternoon();
    }
    // change to tomorrow
    if(this.changeDateByOneDay && !this.changeDateByDays && !this.changeDateByWeek)
    {
      this.changeToTomorrow();
    }
    // change by eneterd days
    if(this.changeDateByDays && !this.changeDateByOneDay)
    {
      this.changeByDays();
    }
    // change by entered weeks
    if(this.changeDateByWeek && !this.changeDateByOneDay)
    {
      this.changeByWeeks();
    }
    if(this.changeDateIndividually)
    {
      this.selectedCall.time_type = this.form.get('time_type').value;
      this.selectedCall.due_date = this.form.get('due_date').value;
      this.selectedCall.due_date = this.getNewCallTime(this.selectedCall);
    }
    await lastValueFrom(this.phoneCallService.updatePhoneCall(this.selectedCall));
    this.selectionSaveEmitter.emit(this.selectedCall)
  }

  selectionTimeTypExact() {
    if(this.form.get('time_type').value == TIMETYPE.EXACT){
      this.setTimeTyp('morning');
    } else {
      this.setTimeTyp('exact');
    }
  }

  setTimeTyp(timeTyp: 'exact'|'morning'|'afternoon') {
    this.form.get('time_type').setValue(timeTyp == 'exact' ? TIMETYPE.EXACT : (timeTyp == 'morning' ? TIMETYPE.MORNING : TIMETYPE.AFTERNOON));
  }

  private MORNING_START = 6 * 60 * 60 * 1000;
  private MORNING_END = 12 * 60 * 60 * 1000;
  private AFTERNOON_START = 12 * 60 * 60 * 1000;
  private AFTERNOON_END = 18 * 60 * 60 * 1000;

  getNewCallTime(call: Call): number {
    if(call.time_type == TIMETYPE.EXACT){
      const time = this.form.get('time').value;
      const hour = time.split(':')[0];
      const min = time.split(':')[1];
      let date = new Date(call.due_date);
      date.setHours(hour);
      date.setMinutes(min);
      return date.getTime();
    } else if(call.time_type == TIMETYPE.MORNING){
      return this.generateUniqueTimestamp(new Date(call.due_date), this.MORNING_START, this.MORNING_END, this.timeStampSets.morningSet);
    } else {
      return this.generateUniqueTimestamp(new Date(call.due_date), this.AFTERNOON_START, this.AFTERNOON_END, this.timeStampSets.afternoonSet);
    }
  }

  generateUniqueTimestamp(date: Date, rangeStart: number, rangeEnd: number, existingTimestamps: Set<number>): number {
    const baseTimestamp = date.setHours(0, 0, 0, 0);
    for(let offset = rangeStart; offset < rangeEnd; offset += (5*60000)) {
        const candidate = baseTimestamp + offset;
        if(!existingTimestamps.has(candidate)) {
            existingTimestamps.add(candidate);
            return candidate;
        }
    }
  }
}

<app-customer-sidebar *ngIf="customers.length > 0 && isPhone && showPhoneMenu"
  [customers]="customersFiltered"
  [filter]="filter"
  [users]="users"
  [paraCustomerId]="paraCustomerId"
  [minimizeSidebar]="minimizeSidebar"
  (selectionCustomerEmitter)="selectionCustomer($event)"
  (searchEmitter)="searchCustomer($event)"
  (filterEmitter)="applyFilter()"
  (applyFilterEmitter)="applyFilter()"
  (removeFilterStatusEmitter)="removeFilterStatus($event)"
  (removeFilterSellerEmitter)="removeFilterSeller($event)"
  (removeFilterClassificationEmitter)="removeFilterClassification($event)"
  (minimizeEmitter)="selectionMinimize()"
></app-customer-sidebar>

<div *ngIf="!showPhoneMenu">
  <div class="view" *ngIf="showInterface == 1">
    <app-customer-sidebar *ngIf="customers.length > 0 && !isPhone"
      [customers]="customersFiltered"
      [filter]="filter"
      [users]="users"
      [paraCustomerId]="paraCustomerId"
      [minimizeSidebar]="minimizeSidebar"
      (selectionCustomerEmitter)="selectionCustomer($event)"
      (searchEmitter)="searchCustomer($event)"
      (filterEmitter)="applyFilter()"
      (applyFilterEmitter)="applyFilter()"
      (removeFilterStatusEmitter)="removeFilterStatus($event)"
      (removeFilterSellerEmitter)="removeFilterSeller($event)"
      (removeFilterClassificationEmitter)="removeFilterClassification($event)"
      (minimizeEmitter)="selectionMinimize()"
    ></app-customer-sidebar>
    <div id="main_body" *ngIf="showView && selectedCustomer">
      <app-customer-details *ngIf="view == 1"
        [selectedCustomer]="selectedCustomer"
        [users]="users"
        [notificationTickets]="notificationTickets"
        (editEmitter)="showInterface = 2"
        (createProjectEmitter)="showInterface = 3"
        (selectionProjectEmitter)="selectionProject($event)"
        (deleteCustomerEmtter)="selectionDeleteCustomer()"
        (showLastAppointmentsEmitter)="showLastAppointments = true"
      ></app-customer-details>
      <app-project-details *ngIf="view == 2"
        [selectedCustomer]="selectedCustomer"
        [selectedProject]="selectedProject"
        [users]="users"
        [notificationTickets]="notificationTickets"
        (closeViewEmitter)="closeProject()"
        (deleteProjectEmitter)="selectionDeleteProject()"
        (selectionEditProject)="showInterface = 3"
      ></app-project-details>
    </div>
    <div id="no_customer_container" *ngIf="!selectedCustomer && customers.length > 0">
      <div class="waymark_slogen">
        <div class="waymark_slogen_row_1">
            <div class="lbl_explore">Explore</div>
            <div class="text">&nbsp;the</div>
        </div>
        <div class="text" [ngStyle]="{'transform': 'translate(0px, -20px)'}">possibilities</div>
      </div>
    </div>
  </div>
  <app-customer-create *ngIf="showInterface == 2"
    [users]="users"
    [customer]="selectedCustomer"
    (cancelEmitter)="cancelCreate()"
    (createSuccessEmitter)="createCustomerSuccess($event)"
    (updateSuccessEmitter)="updateCustomerSuccess($event)"
  ></app-customer-create>
  <app-customer-create-project *ngIf="showInterface == 3"
    [customer]="selectedCustomer"
    [selectedProject]="selectedProject"
    [contacts]="selectedCustomer!.contact_persons"
    (cancelEmitter)="cancelCreate()"
    (createProjectSuccesEmitter)="createProjectSuccess($event)"
    (updateProjectSuccessEmitter)="updateProjectSuccess($event)"
  ></app-customer-create-project>
  <app-dialog-restore *ngIf="showRestoreDialog"
    (selectionRestoreEmitter)="actionChangeStatusCustomer($event)"
    (closeEmitter)="showRestoreDialog = false;"
  ></app-dialog-restore>
  <app-dialog-customer-appointments *ngIf="showLastAppointments"
    [dates]="selectedCustomer.last_appointments"
    [showLastAppointments]="showLastAppointments"
    (closeEmitter)="showLastAppointments = false"
  ></app-dialog-customer-appointments>
</div>
